import React from 'react';
import './App.css'; // Assuming you're using a CSS file for styling

const App: React.FC = () => {
    return (
        <div style={styles.body}> 
            <div style={styles.container}>
                <img 
                    src="/logo.svg" 
                    alt="ItsBounce Logo" 
                    style={styles.img}
                />
                <p style={styles.hiddenText}>I wonder if anyone will read this</p>
                <p>
                    <span style={styles.highlight}>itsbounce.net</span> and <span style={styles.highlight}>wearebounce.net</span> are our only domains.
                    Any other website is an unauthorized imitation or clone.
                </p>
                <p>We're enjoying the break!</p>
                <footer style={styles.footer}>
                    &copy; 2025 ItsBounce.
                </footer>
            </div>
        </div>
    );
};

const styles = {
    body: {
        fontFamily: 'Arial, sans-serif',
        color: '#fff',
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    container: {
        textAlign: 'center' as const,
        maxWidth: '600px',
        padding: '20px',
        borderRadius: '8px',
    },
    img: {
        maxWidth: '150px',
        marginBottom: '20px',
    },
    hiddenText: {
        display: 'none',
    },
    highlight: {
        color: '#ff4747',
        fontWeight: 'bold' as const,
    },
    footer: {
        marginTop: '20px',
        fontSize: '14px',
        color: '#888',
    },
};

export default App;
